















import { Vue, Component } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import AppButton from '@/components/controls/AppButton.vue';

@Component({
    components: { AppButton, Header }
})
export default class NotFound extends Vue {
    private readonly gifsCount = 5;

    private currentGif: number = 0;

    private mounted() {
        this.currentGif = this.getRandomGif();
    }

    private goToMain(): void {
        this.$router.push({ name: 'BattlesListPage' });
    }

    private getRandomGif(): number {
        return Math.floor(Math.random() * this.gifsCount);
    }
}
